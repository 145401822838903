<template>
  <div class="p-8 pt-20">
    <h1 class="font-bold uppercase mb-4 text-2xl">Integrations</h1>
    <CreateIntegration  />
    <IntegrationsTable />
  </div>
</template>

<script>
import CreateIntegration from '@/components/integrations/CreateIntegration.vue';
import IntegrationsTable from '@/components/integrations/IntegrationsTable.vue';

export default {
  components: {
    CreateIntegration,
    IntegrationsTable
  },
  created () {
    const integrationRequest = this.$router.currentRoute.query;
    if (integrationRequest.code) {
      this.authorizeIntegration(integrationRequest);
    } else {
      this.getIntegrations();
    }
  },
  methods: {
    async getIntegrations () {
      this.$store
        .dispatch('getIntegrationsQuery')
        .then(res => {
          if (res.errors) {
            this.$store.dispatch('setIntegrationsErrors', res.errors);
          }
        })
        .catch(err => {
          console.log(err.message);
        });
    },
    async authorizeIntegration (integrationData) {
      this.$store
        .dispatch('authorizeIntegrationMutation', {
          code: integrationData.code,
          grantType: 'AUTHORIZATION_GRANT',
          integrationId: integrationData.state
        })
        .then(res => {
          if (res.errors) {
            this.$store.dispatch('setIntegrationsErrors', res.errors);
          } else {
            this.getIntegrations();
            this.$router.push('/integrations');
          }
        })
        .catch(err => {
          console.log(err.message);
        });
    }
  }
};
</script>
