<template>
    <div class="flex justify-start">
      <div class="w-2/5">
        <p class="text-xs text-black">Available Integrations</p>
        <select v-model="selectedIntegration" data-cy="dropdown">
          <option
            v-bind:key="type.id"
            v-for="type in $store.state.availableIntegrations"
            v-bind:value="type"
          >{{ type.name }}</option>
        </select>
      </div>
      <button @click="addIntegration" :disabled="!selectedIntegration" class="my-6" data-cy="create-device">
        Add Integration
      </button>
      <div class="inline-block">
        <SimpleModal v-if="showParticleLoginModal" @close="showParticleLoginModal = false">
          <div>
            <h2 class="text-center font-bold uppercase mb-4 text-2xl">
              Login with your Particle Account
            </h2>
            <div>
              <div class="flex justify-start" v-if="!mfaToken">
                <BaseInput
                  class="text-theme-darkest mr-4"
                  label="Username"
                  placeholder="username"
                  v-model="username"
                  type="email"
                />

                <BaseInput
                  class="text-theme-darkest mr-4"
                  label="Password"
                  placeholder="password"
                  v-model="password"
                  type="password"
                />
                <div>
                  <button @click="loginParticleUser" class="my-6">
                    <span>Login</span>
                  </button>
                </div>
              </div>
              <div v-if="mfaToken">
                 <BaseInput
                  class="text-theme-darkest mr-4"
                  label="One-time Password"
                  placeholder="1234"
                  v-model="otp"
                  type="text"
                />
                <div>
                  <button @click="setParticleOTP" class="my-6">
                    <span>Submit</span>
                  </button>
                </div>
              </div>
            </div>
            <p class="error" v-if="formError">{{ formError }}</p>
          </div>
        </SimpleModal>
      </div>
    </div>
</template>

<script>
import SimpleModal from '@/components/UI/SimpleModal.vue';
export default {
  components: {
    SimpleModal
  },
  data: function () {
    return {
      selectedIntegration: null,
      showParticleLoginModal: false,
      showParticleMFA: false,
      username: null,
      password: null,
      otp: null,
      mfaToken: null,
      formError: null
    };
  },
  methods: {
    loginParticleUser () {
      this.formError = null;
      fetch('https://api.particle.io/oauth/token', {
        method: 'POST',
        headers: {
          Authorization: `Basic ${btoa('particle:particle')}`,
          'Content-Type': 'application/x-www-form-urlencoded'
        },
        body: `grant_type=password&username=${this.username}&password=${this.password}&expires_in=0`
      })
        .then(response => response.json())
        .then(data => {
          if (data.error === 'mfa_required') {
            this.username = null;
            this.password = null;
            this.mfaToken = data.mfa_token;
            this.showParticleMFA = true;
          } else if (data.error) {
            this.formError = data.error_description;
          } else {
            this.showParticleLoginModal = false;
            this.username = null;
            this.password = null;
            window.location = `${window.location.origin}/integrations?code=${data.access_token}&state=${this.selectedIntegration.id}`;
          }
        });
    },
    setParticleOTP () {
      fetch('https://api.particle.io/oauth/token', {
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded'
        },
        method: 'POST',
        body: `grant_type=urn:custom:mfa-otp&client_id=particle&client_secret=particle&mfa_token=${this.mfaToken}&expires_in=0&otp=${this.otp}`
      })
        .then(response => response.json())
        .then(data => {
          if (data.error) {
            this.formError = data.error_description;
          } else {
            this.showParticleLoginModal = false;
            this.otp = null;
            this.mfaToken = null;
            window.location = `${window.location.origin}/integrations?code=${data.access_token}&state=${this.selectedIntegration.id}`;
          }
        });
    },
    addIntegration () {
      switch (this.selectedIntegration.sensor) {
        case 'awair':
          window.location = this.selectedIntegration.loginUrl;
          break;
        case 'particle':
          this.showParticleLoginModal = true;
          break;
        default:
          window.location = `${window.location.origin}/integrations`;
      }
    }
  }
};
</script>
