<template>
  <div>
    <div v-if="!$store.state.integrationsError.length">
      <p class="text-right">
        Total Integrations: {{ $store.state.configuredIntegrations.length }} | Updated at:
        {{
          new Date().toLocaleString("en-US", {
            hour: "numeric",
            minute: "numeric",
            hour12: true
          })
        }}
      </p>
      <vue-good-table
        data-cy="integrations-table"
        title="Integrations Table"
        :columns="integrationsColumns"
        :rows="$store.state.configuredIntegrations"
        @on-cell-click="onCellClick"
      >
        <div slot="emptystate" class="emptystate" data-cy="empty-state">
          <EmptyTable :loading="$store.state.loading" type="Integrations" />
        </div>
        <template slot="table-row" slot-scope="props">
          <button v-if="props.column.type === 'integrationLink'"
            class="cursor-pointer"
            data-cy="delete-device-btn"
          >
            Revoke Access
          </button>

          <div class="text-center" v-else>
            <p>{{ props.row[props.column.field] }}</p>
          </div>
        </template>
      </vue-good-table>
    </div>
    <TableErrors v-bind:errors="$store.state.integrationsError" />
  </div>
</template>

<script>
import TableErrors from '@/components/UI/TableErrors.vue';

export default {
  data () {
    return {
      integrationsColumns: this.$store.state.integrationsConfig.columns
    };
  },
  components: {
    TableErrors
  },
  methods: {
    onCellClick: function (params) {
      if (params.column.field === 'revoke') {
        this.$store.dispatch('revokeIntegrationAccessMutation', {
          integrationId: params.row.id
        })
          .then((res) => {
            if (res.errors) {
              alert(res.errors[0].message);
            } else {
              this.$store.dispatch('getIntegrationsQuery');
            }
          });
      }
    }
  }
};
</script>
